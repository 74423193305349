<template>
  <div class="custom">
    <video
      ref="video"
      class="custom-player"
      :autoplay="true"
      controls
      :poster="defaultImage"
    >
      <source :src="videoSrc" type="video/mp4" />
    </video>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultImage: require("@/img/imgavatar.png"),
      videoSrc: "https://acdn.mulazim.com/wechat_mini/double_one_prize_222.mp4",
    };
  },
};
</script>

<style scoped>
.custom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
}
.custom-player {
  width: 100%;
  height: 100%;
}
</style>
